import React from "react";
import { graphql } from "gatsby";
import illus404 from "../images/illus404.svg";
import { Header, Footer } from "../components/layout/index";
import { Seo } from "../components/Seo";
import { Link } from "gatsby";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { SectionContainer } from "../components/layout";

const NotFoundPage = () => {
  return (
    <>
      <Seo title="Page introuvable" />
      <Header />
      <div className="flex flex-col w-full items-center pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <h1 className="text-3xl tracking-tight font-extrabold sm:text-4xl text-action-600">
          Page introuvable
        </h1>
        <div className="flex justify-center flex-col">
          <img
            className="py-4 m-0 h-80"
            src={illus404}
            alt="Omnidoc, plateforme de télé-expertise"
          />
        </div>
        <Link
          to="/"
          className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-action-700 bg-action-100 hover:bg-action-200 mt-10"
        >
          <span className="mr-1">
            <ArrowLeftIcon className="w-5" strokeWidth="0.5" />
          </span>
          Retourner à l'accueil
        </Link>
      </div>
      <Footer />
    </>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
